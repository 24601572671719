.profile{
   position: absolute;
   top: 10vh;
   width: 100%;
   display: flex;
   flex-direction: column;
   row-gap: 10px;
   
}

.profile .profile-row1{
   font-size: 1.4em;
   padding-left: 5%;
   padding-top:10px ;
   font-weight: 400;
   color: #3A3A44;
   font-weight: bolder;
}
.profile .profile-row2{
   font-size: 1.2em;
   padding-left: 5%;
   padding-top:10px ;
   color: #3A3A44;
   font-weight: 500;
}
.profile .profile-row2 span{

   font-weight: 900;
}
.profile .profile-row{
   padding-left: 4%;
   padding-top:10px ;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   flex-wrap: wrap;
   column-gap: 25px;
   row-gap: 30px;
  
   
}
.d-flex.justify-content-start.flex-wrap.gap-5
{
   margin-top: 28vh;
   padding-left: 5%;
   width: 100%;
   /*background-color: blue;*/
}
.mx-2
{
   background-color: white;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   height: 42vh;
   border-radius: 12px;
   margin-bottom: 1em;
}
.rating
{
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   font-weight: 600;
   margin: 0.5em;
}
.placeorder
{
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
   margin: 0.7em;
  border: none;
   color: cornflowerblue;

}
.type 
{
   margin: 0.5em;
}
button
{
   border-width: 0;
   background-color: #f7f7f7;
   font-weight: 800;
   border: "none";
}