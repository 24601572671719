/* Default styling for larger screens */
.navbar-nav {
   color: black;
   font-weight: bold;
 }

 .icon{
  text-align: center;
  font-size: 35px;  
  padding: 20px;
  /* font-family: 'Tienne', serif; */
 }
 
 /* Mobile styling for smaller screens */
 @media (max-width: 999px) {
   /* .navbar-nav {
     display: flex;
     flex-direction: column;
     align-items: center;
     /* background-color: #fff;  */
     /* position: absolute;
     width: 100%;
     top: 56px; 
     left: 0;
     z-index: 1;
     text-align: left;
   }
   */
   .navbar-nav li {
     margin: 10px 0;
   }
 
   /* .navbar-toggler {
     margin-left: 10px;
     margin-right: 10px;
   } */
 
   /* .navbar-collapse {
     background-color: #fff; /* Change background color if needed */
     /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
     border-top: 1px solid #ddd;
   }
  */ 
   
 }



 /* @media (max-width: 1000px) {
   .navbar-nav {
     display: none !important;
   }
   .navbar-toggler {
     display: inline-block !important;
   }
 } */
 
 

 /* NavbarWithModalLinks.css */
/* Add your styling for the navbar, modal, and other elements */

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Add this style to your CSS */
.custom-toggle::after {
  display: none; /* Hide the default down arrow */
}

@media (max-width: 1000px) {
  .navbar-toggler-icon {
    display: none;
  }
}
/* navbar.css */

@media (min-width: 992px) and (max-width: 1000px) {
  .navbar-nav {
    display: none;
    visibility: hidden;
  }
}
