/* ProductPage.css */

.zoom-card:hover .zoom {
   transform: scale(1.1);
   transition: transform 0.5s;
 }
 
 .zoom {
   overflow: hidden;
 }
 
 .zoom img {
   transition: transform 0.5s;
 }

 /* ProductPage.css */

.zoom-card {
  position: relative;
}

.zoom-card .view {
  overflow: hidden;
}

.zoom-card:hover .view::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45); /* Adjust the opacity as needed */
  z-index: 1;
}

.zoom-card:hover .stripe {
  opacity: 1;
}

.stripe {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  color: white;
  z-index: 2;
  text-align: center;
  transition: opacity 0.3s;
}

.stripe a {
  text-decoration: none;
  color: white;
  
}

.profile-row1{
  font-size: 1.4em;
  padding-left: 5%;
  padding-top:10px ;
  font-weight: 400;
  color: #3A3A44;
  font-weight: bolder;
}

 