/* heading.css */

.heading-page {
   position: relative;
   display: grid;
   grid-template-columns: 48rem 32rem;
   gap: 3rem;
 }
 
 .hero-para {
   font-size: 18px;
   text-align: left;
   padding: 15px;
   font-weight: bold;
 }
 
 .hero-heading {
   font-size: 30px;
   text-align: left;
   padding: 15px;
   font-family: 'Tienne', serif;
   margin: none;
 }
 
 .img {
   height: 280px;
   border-radius: 10%;
   padding: 10px;
 }
 
 @media screen and (max-width: 800px) {
   .hero-heading {
     font-size: 1.5rem;
   }
 }
 
 @media screen and (min-width: 1000px) {
   .heading-page {
     position: relative;
     display: grid;
     grid-template-columns: 30rem 20rem;
     gap: 5rem;
     height: 300px;
   
   }
 }
 @media screen and (min-width: 1000px) and (max-width: 1109px) {
  
  .heading-page {
    position: relative;
    display: grid;
    grid-template-columns: 35rem 18rem;
    gap: 1rem;
    /* margin-left: -10%; */
  }

  .img {
    height: 250px;
    width: 210;
    border-radius: 10%;
    padding: 10px;
  }
 }
 
 @media screen and (max-width: 999px) {
   .heading-page {
     position: relative;
     display: grid;
     grid-template-columns: 25rem 15rem;
     gap: 3rem;
   }

   .hero-para {
      font-size: 16px;
      text-align: left;
      /* padding: 10px; */
      font-weight: bold;
    }
 
   .hero-heading {
      font-size: 25px;
      text-align: left;
      padding: 15px;
      font-family: 'Tienne', serif;
   }
 
   .img {
     height: 250px;
     width: 250px;
     border-radius: 10%;
     padding: 10px;
   }
 }
 
 @media screen and (max-width: 800px) {
   .heading-page {
     position: relative;
     display: grid;
     grid-template-columns: 25rem 15rem;
     gap: 5rem;
   }
 
   .hero-para {
      font-size: 15px;
      text-align: left;
      padding: 10px;
      font-weight: bold;
     
    }
 
   .hero-heading {
      font-size: 25px;
      text-align: left;
      padding: 10px;
      font-family: 'Tienne', serif;
   }
 
   .img {
     height: 230px;
     width: 230px;
     border-radius: 10%;
     padding: 10px;
   }
 }
 
 @media screen and (max-width: 750px) {
   .heading-page {
     position: relative;
     display: grid;
     grid-template-columns: 20rem 15rem;
     gap: 1rem;
     padding: auto;
   }
   .hero-para {
      font-size: 15px;
      text-align: left;
      padding: 10px;
      font-weight: bold;
     
    }
 
   .hero-heading {
      font-size: 23px;
      text-align: left;
      padding: 10px;
      font-family: 'Tienne', serif;
   }
 
 }
 
 @media screen and (max-width: 690px) {
  .heading-page {
    position: relative;
    display: grid;
    grid-template-columns: 19rem 12rem;
    /* gap: 3rem; */
  }

  .hero-para {
     font-size: 14px;
     text-align: left;
     padding: 10px;
     font-weight: bold;
     /* margin-left: 8px; */
   }

  .hero-heading {
     font-size: 23px;
     text-align: left;
     padding: 10px;
     font-family: 'Tienne', serif;
  }

  /* .img {
    height: 250px;
    width: 250px;
    border-radius: 10%;
    padding: 10px;
  } */
 
 
   
 }
 @media screen and (max-width: 529px) {
   .heading-page {
     position: relative;
     display: flex;
     flex-direction: column;
     align-items: center;
     text-align: center;
     padding: auto;
     /* margin-top: -20%; */
     
   }
 
   .card-body {
     text-align: center;
   }
 
   .hero-para {
     font-size: 14px;
     text-align: center;
     font-weight: bold;
   }
 
   .hero-heading {
     font-size: 22px;
     text-align: center;
     font-family: 'Tienne', serif;
   }
   .img-card{
    margin-top: -50px;
    margin-bottom: -50px;
   }
 
   .img {
     height: 190px;
     width: 190px;
     border-radius: 10%;
   }
 }
 
 
 
 @media screen and (max-width: 400px) {
   .heading-page {
     position: relative;
     display: flex;
     flex-direction: column;
     align-items: center;
     text-align: center;
     /* margin-top: -10%; */
   }
   .header-card {
      width: 250px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      /* margin-left: 10px; */
      padding-top: -15%;
    }
    
    .hero-para{
      font-size: 12px;
      text-align: center;
     font-weight: bold;
    }
    .hero-heading {
      font-size: 20px;
      line-height: 1.4; 
      margin: 5px 0; 
      font-family: 'Tienne', serif;
    }
    
    .hero-heading {
      padding: 10px; 
    }
    
   .img-card {
     margin-top: -50px;
     margin-bottom: -50px;
     padding-top: -20%;
     padding-bottom: -20%;
   }
 
   .img {
     height: 200px;
     width: 200px;
     border-radius: 10%;
   }
 }
 

 @media screen and (max-width: 260px) {
   .heading-page {
     position: relative;
     display: flex;
     flex-direction: column;
     align-items: center;
     text-align: center;
     /* margin-top: -30%; */
   }
   .header-card {
      width: 220px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      /* margin-left: 20px; */
    }
    
    .hero-para{
      font-size: 8px;
      text-align: center;
     font-weight: bold;
    }
    .hero-heading {
      font-size: 15px;
      line-height: 1.4; /* Adjust line height for better spacing */
      margin: 5px 0; /* Adjust top and bottom margin for spacing between lines */
      font-family: 'Tienne', serif;
    }
    
    .hero-heading {
      padding: 10px; /* Adjust padding for more space around the heading */
    }
    
   .img-card {
     margin-top: -30px;
     margin-bottom: -30px;
   }
 
   .img {
     height: 200px;
     width: 180px;
     border-radius: 10%;
   }
 }
 

 

 @media screen and (max-width: 260px) {
   .heading-page {
     position: relative;
     display: flex;
     flex-direction: column;
     align-items: center;
     text-align: center;
     /* margin-top: -80%; */
   }
   .header-card {
      width: 180px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      /* margin-left: 15px; */
    }
    
    .hero-para{
      font-size: 10px;
      text-align: center;
     font-weight: bold;
     
    }
    .hero-heading {
      font-size: 17px;
      line-height: 1.4; /* Adjust line height for better spacing */
      margin: 5px 0; /* Adjust top and bottom margin for spacing between lines */
      font-family: 'Tienne', serif;
      text-align: center;
      width: 100%; /* or adjust as needed */
      margin-left: 3px; /* or adjust as needed */
      margin-right: 3px; /* or adjust as needed */
      padding-bottom: 3px;
      
    }
    
    .hero-heading {
      padding: 5px; /* Adjust padding for more space around the heading */
    }
    
   .img-card {
     margin-top: -50px;
     margin-bottom: -50px;
   }
 
   .img {
     height: 180px;
     width: 160px;
     border-radius: 5%;
   }
 }
 



 @media screen and (min-width: 1000px) {
   .card-body:nth-of-type(2) {
     font-size: 36px;
   }
 }
 