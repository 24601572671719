/* Add this to your Sidenav.css file */
.custom-modal {
   display: flex;
   align-items: stretch;
   height: 100vh;
   margin: 0;
  
 }
 
 .custom-modal .modal-content {
   width: 55%; /* Set the width as needed */
   margin: 0;
   background-image: url('../../assets/img/bg.jpg');
   background-size: cover;
   background-repeat: no-repeat;
   
 }
 /* .navs{
   background-color: transparent;
 }
  */
 .custom-modal .modal-body {
   overflow-y: auto;
   /* background-color: transparent; */
 }

 
 
 .custom-modal .modal-body {
   overflow-y: auto;
   align-items: flex-start; 
  
 }
 
 
 .custom-modal ul {
   
   list-style: none; /* Remove default list styling */
   padding: 0;
 }
 
 .custom-modal .nav-link {
   color: black;
   font-weight: bold;
   text-decoration: none;
 }
 
 .custom-modal .nav-link:hover {
   text-decoration: underline; /* Add underline on hover if needed */
 }
 
 h3{
   font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
   font-weight: 800;
}
/* 
@media screen and (min-width: 566px) {

   
 .custom-modal .modal-content {
  
  
   display: flex;
   align-items: stretch;
   height: 100vh;
   margin-left: -10%;
 }
} */
