*{
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}


.navbar{
   display: flex;
   justify-content: space-around;
   align-items: center;
   color:black;
   padding: 5px 2%;
   margin: 5px 2%;
}
.nav-links{
   display: flex;
   
   list-style: none;
   width: 75%;
   
}
.nav-list{
   display: flex;
   list-style: none;
   
}
.link-tab{
   text-decoration: none!important;
   margin: 0px 20px;
   color: black;
   font-size: 17px;
   font-weight: 600; 

}
.nav a{
   
      text-decoration: none;
      margin: 0px 20px;
      color: black;
      font-size: 17px;
      font-weight: 600; 
    
}
.logo h4{
   font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.tablet-menu{
   display: none;
}
.mobile-menu{
   display: none;
}
@media(max-width:870px){
   .nav-links-tablet{
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      background-image: url("../../../assets/img/bg.jpg");
      width:50%;
      height: 100vh;
      list-style: none;
      transition: 0.5 all ease-out;
      z-index: 5;
   }
   .signup,.login{
      color: black;
      font-size: 20px;
      text-align: left;
      cursor: pointer;
      transition: 0.5 all ease;
      margin-top: 20px;
   }
   .tablet-menu{display: block;
   font-size: 25px;
top:0;
right: 25px;
position: absolute;
z-index: 5;
padding: 10px 0;
}
.nav-links{
   display: none;
}
.signup{
   background-color: transparent;
   border: none;
   
}
}
@media(max-width:736px){
   .logo{ 
      margin: 0px auto ;
      /* font-size: 10px;  */

   }
  .hamburger{
   margin-left: -10px;
  }
.mobile-menu{
   display: block;
   font-size: 25px;
   top:0;
   left: 25px;
   position: absolute;
   z-index: 5;
   padding: 10px 0;
}
.nav-list{
   display: none;
}
.nav-list-mobile{
   display: block;
   position: absolute;
   left: 0;
   top: 40px;
   background-image: url("../../../assets/img/bg.jpg");
   width:50%;
   height: 100vh;
   list-style: none;
   transition: 0.5 all ease-out;
   z-index: 5;
}
}

.order-user-detail img{
   width: 50px;
   border-radius: 50px;
   margin: 0px 20px;
}

.order-user-detail p{ 
   font-size: 17px; 
   margin: 0px;
   font-weight: 600; 
   text-decoration: none;
   color: black;
}

.shopping-cart{
   /* style={{ fontSize: '25px', margin: "0px 40px" }}  */
   font-size: 25px;
   margin: 0px 40px;
}

@media(max-width:1245px){

   
.order-user-detail img{
   width: 30px;
   border-radius: 50px;
   margin: 0px 10px;
   /* margin-left: -30px; */
}

.order-user-detail p{ 
   font-size: 12px; 
   margin: 0px;
   font-weight: 600; 
   /* margin-top: 30px; */
}

.shopping-cart{
   /* style={{ fontSize: '25px', margin: "0px 40px" }}  */
   font-size: 20px;
   margin: 0px 10px;
}



}

@media (max-width:325px) {

   .logo h4{
      /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
      /* margin-left: 30px; */
      visibility: hidden;
   } 
   .hamburger{
      margin-left: 0px;
     }
     .order-user-detail{
      margin-right: -30px;
     }
   
}