.background-container {
  background-image: url('../../assets/img/bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  object-fit: fill;
  min-height: 1000px;
  margin-top: -5px;
}

/* background image from 0-1000 */
@media (min-width: 531px) and (max-width: 1000px) {
  
  .background-container {
    background-image: url('../../assets/img/bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: auto;
    /* min-height: 1300px; */
  }

}

@media (min-width: 0px) and (max-width: 530px) {
  
  .background-container {
    background-image: url('../../assets/img/bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: auto;
    min-height: 1400px;
  }

}

.landing-body {
  width: 100%;
  position: absolute;
  top: 60px;
  height: calc(100% - 60px);
  min-height: 800px;
  max-height: 900px;
}


.landing-content h2 span {
  color: #0913FA;
}

.three-lables {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  
}

.three-lables div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  border: 3px solid black;
  height: 260px;
  width: 170px;
  margin: 0px 40px;
  border-radius: 20px;
}

.three-lables div img {
  width: 120px;
}

.three-lables div p {
  font-size: 18px;
  font-weight: 800;
}

.links {
  font-size: 18px;
  font-weight: 800;
  text-decoration: none;
  color: black;
  transition: transform 0.3s ease; 
}

.links:hover{
  color: black;
  text-decoration: none;
  transform: scale(1.1)
}

.social-icons {
  text-align: center;
  margin: 50px 0px;
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  font-size: 30px;
  color: #0913FA;
}

.social-icons .icon {
  margin: 0px 10px;
}

/* Navbar Styles */
/* .navbar-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 5% !important;
}

.navbar-div .logo h4 {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.navbar-div .nav {
  display: flex;
}

.navbar-div .nav a {
  text-decoration: none;
  margin: 0px 20px;
  color: black;
  font-size: 17px;
  font-weight: 600;
}
 */
.auth-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-btn button {
  border: none;
  background: transparent;
  font-size: 17px;
  font-weight: 600;
  padding: 5px 10px;
  margin: 0px 10px;
  border-radius: 5px;
}

.auth-btn button a {
  color: black;
  text-decoration: none;
}

.auth-btn button.signup-btn {
  border: none;
  background: transparent;
  font-size: 17px;
  font-weight: 600;
  background-color: #0913FA;
  padding: 5px 10px;
  margin: 0px 10px;
  color: white;
  border-radius: 5px;
}

.auth-btn button.signup-btn a {
  color: white;
}

.icon {
  font-size: 24px;
  margin: 5px;
  color: #fff;
}

.callus {
  text-align: left;
}

.footer-row {
  margin-top: 20px;
  font-size: 14px;
}

.footer-year {
  font-weight: bold;
}

.footer-row a {
  color: #181717;
  text-decoration: none;
  margin: 0 10px;
}

.footer-row a:hover {
  text-decoration: underline;
}

/* @media (max-width: 1000px) {
  .navbar-collapse {
    display: none;
    background-color: rgb(201, 222, 241);
    
    width: auto;
    height: auto;
  } 

  .navbar-toggler {
    display: block;
    background-color: rgb(201, 222, 241);
  }
} */


@media (mn-width: 999px) and (max-width: 1010px) {
  .landing-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 40px;
    padding: 15px;
  }

  .landing-content p {
    font-size: 13px;
  }

  .landing-content h2 {
    text-align: center;
    font-size: 25px;
    padding: 15px;
    font-family: 'Tienne', serif;
  }

  .three-lables {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .three-lables div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    border: 2px solid black;
    height: 180px;
    width: 120px;
    margin: 0px 20px;
    border-radius: 20px;

  }

  .three-lables div img {
    width: 50pxpx;
  }

  .three-lables div p {
    font-size: 15px;
    font-weight: 600;
  }

  .links {
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
    color: black;
  }
}

@media (min-width: 781px) and (max-width: 999px) {

  .three-lables {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .three-lables div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    border: 2px solid black;
    height: 180px;
    width: 140px;
    margin: 0px 20px;
    border-radius: 20px;
  }

  .three-lables div img {
    width: 90px;
  }

  .three-lables div p {
    font-size: 18px;
    font-weight: 600;
  }

  .links {
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
    color: black;
  }
}
@media  (max-width: 780px) {
  .three-lables {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: -15px;
    display: grid;
    grid-template-columns: 11rem 11rem;
    gap: 2.5rem;
  }

  .three-lables div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    border: 2px solid black;
    height: 180px;
    width: 140px;
    margin: 0px 4px;
    border-radius: 20px;
    margin-bottom: 10px;
    margin-left: 20px;
  }

  .three-lables div img {
    width: 100px;
  }

  .three-lables div p {
    font-size: 18px;
    font-weight: 800;
    color: black;
    padding-left: 15px;
    padding-right: 15px;
  }

  .links {
    font-size: 10px;
    font-weight: 400;
    text-decoration: none;
    color: black;
  }
  .icon-div{
    margin-left: 10px;
    /* margin: auto; */
  }
}


@media (max-width: 615px) {
  .landing-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 30px;
    padding: 10px;
  }

  .landing-content p {
    font-size: 10px;
  }

  .landing-content h2 {
    text-align: center;
    font-size: 20px;
    padding: 10px;
    font-family: 'Tienne', serif;
  }

  .three-lables {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: -30px;
    display: grid;
    grid-template-columns: 9rem 9rem;
    gap: 2.5rem;
  }

  .three-lables div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    border: 2px solid black;
    height: 173px;
    width: 120px;
    margin: 0px 4px;
    border-radius: 20px;
    margin-bottom: 10px;
    margin-left: 20px;
  }

  .three-lables div img {
    width: 90px;
  }

  .three-lables div p {
    font-size: 18px;
    font-weight: 800;
    color: black;
    padding-left: 15px;
    padding-right: 15px;
  }

  .links {
    font-size: 10px;
    font-weight: 400;
    text-decoration: none;
    color: black;
  }
  .icon-div{
    margin-left: 10px;
    /* margin: auto; */
  }
}

@media (max-width: 400px) {
  .three-lables {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: -60px;
    display: grid;
    grid-template-columns: 8rem;
    gap: 1rem;
  }

  .three-lables div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    border: 2px solid black;
    height: 180px;
    width: 160px;
    margin: 0px 4px;
    border-radius: 20px;
    margin-bottom: 10px;
    margin-left: 12px;
  }

  .three-lables div img {
    width: 110px;
  }

  .three-lables div p {
    font-size: 17px;
    font-weight: 800;
    color: black;
    padding-left: 15px;
    padding-right: 15px;
  }

  .links {
    font-size: 10px;
    font-weight: 400;
    text-decoration: none;
    color: black;
  }
  .icon-div{
    margin-left: 10px;
    margin-top: 10%;
  }
}

@media (max-width: 359px) {
  .three-lables {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: -30px;
    display: grid;
    grid-template-columns: 8rem;
    gap: 1rem;
  }

  .three-lables div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    border: 2px solid black;
    height: 170px;
    width: 150px;
    margin: 0px 4px;
    border-radius: 20px;
    margin-bottom: 10px;
    /* margin-left: 15px; */
  }

  .three-lables div img {
    width: 100px;
  }

  .three-lables div p {
    font-size: 17px;
    font-weight: 800;
    color: black;
    padding-left: 15px;
    padding-right: 15px;
  }

  .links {
    font-size: 10px;
    font-weight: 400;
    text-decoration: none;
    color: black;
  }
  .icon-div{
    margin-left: 10px;
    margin-top: 20%;
  }
}

@media (max-width: 329px) {
  .three-lables {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: -10px;
    display: grid;
    grid-template-columns: 8rem;
    gap: 0.5rem;
  }

  .three-lables div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    border: 2px solid black;
    height: 170px;
    width: 140px;
    margin: 0px 4px;
    border-radius: 20px;
    margin-bottom: 10px;
    margin-left: 0px;
  }
  

  .three-lables div img {
    width: 90px;
  }

  .three-lables div p {
    font-size: 20px;
    font-weight: 800;
    color: black;
    padding: 10px;
  }

  .links {
    font-size: 10px;
    font-weight: 400;
    text-decoration: none;
    color: black;
  }
  .icon-div{
    margin-top: 30%;
  }
}



@media (max-width: 271px) {
  .three-lables {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: 0;
    display: grid;
    grid-template-columns: 5rem ;
    gap: 2rem;
    margin-left: -8px;
  }
 

  .icon-div{
    margin-left: 10px;
    margin-top: 30%;
  }

  .three-lables div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    border: 2px solid black;
    height: 160px;
    width: 135px;
    margin: 0px 4px;
    border-radius: 20px;
    margin-bottom: 10px;
    margin-left: -25px;
  }

  .three-lables div img {
    width: 90px;
  }

  .three-lables div p {
    font-size: 18px;
    font-weight: 600;
    color: black;
    padding: 5px;
  }

  .links {
    font-size: 8px;
    font-weight: 300;
    text-decoration: none;
    color: black;
  }
}




@media (max-width: 229px) {
  .three-lables {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: 0;
    display: grid;
    grid-template-columns: 4rem;
    gap: 1.5rem;
  }

  .three-lables div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    border: 2px solid black;
    height: 160px;
    width: 120px;
    margin: 0px 4px;
    border-radius: 13px;
    margin-bottom: 10px;
    margin-left: -30px;
  }

  .three-lables div img {
    width: 90px;
  }

  .three-lables div p {
    font-size: 18px;
    font-weight: 800;
    color: black;
  }

  .links {
    font-size: 6px;
    font-weight: 200;
    text-decoration: none;
    color: black;
  }
  .icons{
    font-size: 10px;
  }
  
  .icon-div{
    margin-top: 20%;
  }
}